import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { BaseButton } from "./styledComponents"
import BaseImg from "./baseImg"
import { useAnimatedBorder } from "../helpers"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"

const ArticleHeader = styled.h1`
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-top: 3.25rem;
  margin-bottom: 0.75rem;
`

const ArticleContent = styled.div`
  margin-bottom: 3.5rem;
  & > p {
    margin: 0;
  }
`

const LinkWrapper = styled(Link)`
  :hover {
    color: ${(props) => props.theme.primary};
  }
`

const ArticleBox = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 330px;
  padding: 2.25rem 0;
`

const Border = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  height: 100%;
  left: 2.25rem;
  width: calc(100% - 4.5rem);
  height: calc(215px + 4.5rem);
  border-top: 1px solid ${(props) => props.theme.secondary};
  ${(props) =>
    props.side === "bottom" &&
    css`
      top: auto;
      bottom: 0;
      height: 7rem;
      border-top: none;
      border-bottom: 1px solid ${(props) => props.theme.secondary};
    `}
  ::before,
  ::after {
    content: "";
    top: 0;
    position: absolute;
    ${(props) =>
      props.side === "bottom" &&
      css`
        top: auto;
        bottom: 0;
        border-left: none;
      `}
    height: 100%;
    transition: transform 1s ease-out;
  }
  ::before {
    left: 0;
    border-left: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(${(props) => 1 + props.scale});
    transform-origin: top;
    ${(props) =>
      props.side === "bottom" &&
      css`
        transform: scaleY(${(props) => 1 - props.scale});
        transform-origin: bottom;
      `}
  }
  ::after {
    right: 0;
    border-right: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(${(props) => 1 - props.scale});
    transform-origin: top;
    ${(props) =>
      props.side === "bottom" &&
      css`
        transform: scaleY(${(props) => 1 + props.scale});
        transform-origin: bottom;
      `}
  }
`

const ReadMore = styled(BaseButton)`
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const ArticleElement = ({ style, title, excerpt, localFile, path }) => {
  const { wordpressPage } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          global_settings {
            readMore
          }
        }
      }
    }
  `)
  const [scale, boxRef] = useAnimatedBorder(0.2)

  return (
    <ArticleBox style={style} ref={boxRef}>
      <Border side="top" scale={scale} />
      <LinkWrapper to={path}>
        <div>
          <BaseImg style={{ width: "100%" }} localFile={localFile} />
          <ArticleHeader>{decodeHtmlCharCodes(title)}</ArticleHeader>
          <ArticleContent dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
      </LinkWrapper>
      <div>
        <ReadMore to={path} size="small">
          {wordpressPage.cmb2.global_settings.readMore}
        </ReadMore>
      </div>
      <Border side="bottom" scale={scale} />
    </ArticleBox>
  )
}

export default ArticleElement
