import { useState } from "react"
import axios from "axios"

export const useNewsletterForm = (success, error, site) => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    text: "",
    sent: false,
  })

  const handleEmail = (event) => {
    const { value } = event.target
    setEmail(value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append("newsletter-email", email)
    setIsLoading(true)

    setTimeout(() => {
      setResponseMessage({
        text: success,
        sent: true,
      })
      setIsLoading(false)
      setEmail("")
    }, 1000)

    axios
      .post(
        `${site.siteMetadata.adminSiteUrl}/wp-admin/admin-post.php`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => response)
      .then(() => {
        console.log("E-mail sent")
      })
      .catch((error) => {
        setResponseMessage({
          text: error,
          sent: false,
        })
        throw new Error(`Response returned status code: ${error}`)
      })
  }

  return { email, responseMessage, isLoading, handleEmail, handleSubmit }
}
