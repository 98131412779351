import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BaseButton } from "./styledComponents"
import { Container } from "./styledComponents"
import GoTopArrow from "../components/goTopArrow"
import { MediumMobileFont, SmallMobileFont } from "./typography"
import FlowMapImage from "./flowMapImage"

const Header = styled.h2`
  text-align: center;
  margin-bottom: 0.75rem;
`
const Content = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 74px 1rem;
  @media screen and (max-width: 768px) {
    margin: 0 0 1rem;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 620px;
  margin: 150px auto 250px;
  padding: 46px;
  border: 1px solid ${(props) => props.theme.secondary};
  @media screen and (max-width: 768px) {
    padding: 26px 12px;
    margin: 90px auto 120px;
  }
`

const WingImageContainer = styled.div`
  position: absolute;
  z-index: -1;
  bottom: -50%;
  width: 26.5vw;
  left: ${(props) => (props.side === "left" ? "auto" : "60%")};
  right: ${(props) => (props.side === "left" ? "60%" : "auto")};
  transform: rotateY(${(props) => (props.side === "left" ? "0" : "180deg")});
  @media screen and (max-width: 900px) {
    width: 40vw;
  }
  @media screen and (max-width: 500px) {
    bottom: -25%;
  }
`

const LastCTASection = ({ title, text, buttonText, buttonUrl }) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "lastCtaWing" }) {
          publicURL
        }
      }
    `
  )
  return (
    <Container>
      <ContentWrapper>
        <WingImageContainer side="left">
          <FlowMapImage
            imagePublicURL={data.file.publicURL}
            size={{ width: 384, height: 378 }}
          />
        </WingImageContainer>
        <Header>
          <MediumMobileFont>{title}</MediumMobileFont>
        </Header>
        <div style={{ maxWidth: "90%" }}>
          <Content>
            <SmallMobileFont>{text}</SmallMobileFont>
          </Content>
        </div>
        {buttonText && <BaseButton to={buttonUrl}>{buttonText}</BaseButton>}
        <WingImageContainer>
          <FlowMapImage
            flipMouse="x"
            imagePublicURL={data.file.publicURL}
            size={{ width: 384, height: 378 }}
          />
        </WingImageContainer>
      </ContentWrapper>
      <GoTopArrow />
    </Container>
  )
}

export default LastCTASection
